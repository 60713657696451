<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col lg="12" sm="12" v-if="deviceTypology != 'Blast Chiller'">
        <reports-info-header/>
        <device-info-generic-banner />
      </b-col>
      <b-col lg="12" sm="12" v-else-if="deviceTypology == 'Blast Chiller'">
        <reports-info-header/>
        <device-info-abatedor-banner />
      </b-col>
    </b-row>
    <b-row  v-if="!['Acura', 'Exige'].includes(deviceTypology)" class="match-height">
      <b-col lg="12" sm="12">
        <reports-haccp />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <reports-metricas />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ReportsInfoHeader from "./ReportsInfoHeader.vue";
import ReportsHaccp from "./ReportsHACCP.vue";
import ReportsMetricas from "./ReportsMetricas.vue";
import { BRow, BCol } from "bootstrap-vue";
import DeviceInfoGenericBanner from "../generic-device/DeviceInfoGenericBanner.vue";
import DeviceInfoAbatedorBanner from "../device/DeviceInfoAbatedorBanner.vue";

export default {
  components: {
    BRow,
    BCol,
    ReportsHaccp,
    ReportsMetricas,
    ReportsInfoHeader,
    DeviceInfoGenericBanner,
    DeviceInfoAbatedorBanner
  },
  data() {
    return {
      data: {},
    };
  },
  computed:{
    deviceTypology()
    {
      return this.$store.state.devices.currentDevice.typology; 
    }    
  },
  methods:
  {
  },
  created() {
    this.$store.dispatch("devices/getSingleDevice", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getEmailSingleDeviceRoles", { 
      context: this, 
      id:this.$route.params.id
    });
    this.$store.dispatch("metrics/getSchedule", { 
      context: this, 
      device_id:this.$route.params.id
    });
    
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-home.scss";
@import "@core/scss/vue/pages/page-device.scss";
</style>

